import React from 'react';
import contact from "../assets/images/contact.jpg";
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const ContactUs = () => {
  return (
    <>
      <Container>
        <Heading id='contact'>CONTACT US!</Heading>
        <ImageContainer alt='contact-person' src={contact} />
        <SubHeading>or email us at <i><b>oliver.babe73@yahoo.com</b></i></SubHeading>
      </Container>
    </>
  );
};

const Heading = styled.div`
    padding-top:1rem;
    font-size: 8vw;
    font-weight: bold;
`;

const SubHeading = styled.div`
    font-size: 3vw;

    @media (max-width: 395px) {
    font-size: 5vw;
    font-size: 3vh;
    margin-left: 0vh
}

@media (max-width: 680px) {
    font-size: 5vw;
    font-size: 3vh;
    margin-left: 0vh
}

@media (max-width: 1152px) {
    font-size: 5vw;
    font-size: 3vh;
    margin-left: 0vh
}
`;

const ImageContainer = styled.img`
  image: url(${contact});
  height: 70%;
  width: 70%;
  border-radius: 10px;
  margin-bottom: 20px;
`;

export default ContactUs;
