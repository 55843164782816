import React from 'react';
import styled from 'styled-components';
import background from "../assets/images/cars.jpg";
import { Container, Nav, Navbar } from 'react-bootstrap';


const Hero = () => {
    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Nav className="ms-auto">
                        <Nav.Link href="#services">Services</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <HeroContainer>
                <Container>
                    <TextContainer>
                        <h1>V TRANSPORT, LLC</h1>
                        <h3>PRIVATE SHUTTLE</h3>
                    </TextContainer>
                </Container>
            </HeroContainer>
        </>

    );
};

const HeroContainer = styled.div`
    background-image: linear-gradient(rgba(0, 0, 0, 0.106), rgba(0, 0, 0, 0.034)), url(${background});
    /* Set a specific height */
    height: 100vh;
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center
`;

const HeroText = styled.div`
padding-top:2rem;
font-size: 8vw;
font-weight: bold;
color: white
`;

const HeroSubText = styled.div`
font-size: 3vw;
margin-left: 90vh;
color: white;

@media (max-width: 395px) {
    font-size: 5vw;
    font-size: 3vh;
    margin-left: 0vh
}

@media (max-width: 680px) {
    font-size: 5vw;
    font-size: 3vh;
    margin-left: 0vh
}

@media (max-width: 1152px) {
    font-size: 5vw;
    font-size: 3vh;
    margin-left: 0vh
}
`;

export default Hero;
