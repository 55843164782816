import Hero from "./components/hero";
import 'bootstrap/dist/css/bootstrap.min.css';
import Services from "./components/services";
import ContactUs from "./components/contact";

function App() {
  return (
    <div className="App">
      <Hero />
      <Services />
      <ContactUs />
    </div>
  );
}

export default App;
