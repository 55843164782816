import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const Services = () => {
    return (
        <>
            <Container>
                <Heading id='services'>SERVICES OFFERED:</Heading>
                <SubHeading>
                    <ul>
                        <li>Dog Sledding</li>
                        <li>Snow Mobiling</li>
                        <li>ATV</li>
                        <li>Fly Fishing</li>
                        <li>Private Hire</li>
                    </ul>
                </SubHeading>

            </Container>
        </>
    );
};

const Heading = styled.div`
    padding-top:1rem;
    font-size: 8vw;
    font-weight: bold;
`;

const SubHeading = styled.div`
    font-size: 3vw;

    @media (max-width: 395px) {
    font-size: 5vw;
    font-size: 3vh;
    margin-left: 0vh
}

@media (max-width: 680px) {
    font-size: 5vw;
    font-size: 3vh;
    margin-left: 0vh
}

@media (max-width: 1152px) {
    font-size: 5vw;
    font-size: 3vh;
    margin-left: 0vh
}
`;

export default Services;
